import React from 'react'

import Layout from '../components/layout'
import truck from '../images/car-illustration-tow.svg'
import checkMark from '../images/06007-okay-c.svg'

const Truck = () => (
  <img src={truck} style={styles.footer.img} alt="This is a tow truck" />
)
const CheckMark = () => (
  <img src={checkMark} style={styles.content.img} alt="Success" />
)
const Divider = () => <div style={styles.divider} />
const VehicleLocation = () => (
  <div style={styles.vehicleLocation}>Vehicle Location</div>
)
const AddressContainer = ({ children }) => (
  <div style={styles.addressContainer}>{children}</div>
)
const Address = ({ address }) => <p style={styles.address}>{address}</p>
const Bullseye = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd" transform="translate(2 2)">
      <circle cx="8" cy="8" r="8" stroke="#0033A0" strokeWidth="4" />
      <circle cx="8" cy="8" r="4.632" fill="#0033A0" />
    </g>
  </svg>
)

const SuccessPage = ({ location }) =>
  console.log(location) || (
    <Layout pageTitle="Roadside Assistance">
      <div style={styles.content}>
        <CheckMark />
        <h1 style={styles.content.h1}>Thank you</h1>
        <p>We have received your vehicle's location.</p>
        <Divider />
        <VehicleLocation />
        <AddressContainer>
          <Bullseye />
          <Address address={location.state ? location.state.address : ''} />
        </AddressContainer>
      </div>
      <div style={styles.footer}>
        <Truck />
      </div>
    </Layout>
  )

export default SuccessPage

const styles = {
  content: {
    textAlign: 'center',
    height: '55%',
    backgroundImage: 'linear-gradient(to bottom, #f3f6f9, #ffffff)',
    h1: {
      fontWeight: '575',
      margin: '0',
    },
    img: {
      marginTop: '8%',
      marginBottom: '0',
      height: '44px',
      width: '44px',
    },
  },
  footer: {
    display: 'flex',
    height: '45%',
    img: {
      marginBottom: '0',
      alignSelf: 'flex-end',
      width: '100%',
    },
  },
  divider: {
    borderBottom: '1px solid darkgrey',
    height: '3px',
    margin: '0 auto',
    width: '80%',
  },
  addressContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  vehicleLocation: {
    display: 'inline-block',
    color: '#333333',
    fontWeight: 'bold',
    fontSize: '.765rem',
    marginTop: '1rem',
  },
  address: {
    display: 'flex',
    fontSize: '.7rem',
    marginLeft: '10px',
    width: '70%',
  },
}
